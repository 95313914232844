import { styles } from "@/reports/reportStyles";
import { REFERENCE_TABLE } from "@/constants/ReferenceTables";
import { useConfigStore } from "@/stores/config";
import { getReportFooter, getReportHeader } from "@/reports/ReportUtil";
import { useInspectionStore } from "@/stores/inspection";
import useDateField from "@/composables/dateField.js";
import {
  getIN15Value,
  getIN15ValueFromMaterials,
  areThereNonControllingIN15Changes,
} from "@/composables/keyFields.js";

function setRatingsDocDefinition(structure) {
  const configStore = useConfigStore();
  const { getFormattedDateStringNoTime } = useDateField();
  const inspectionStore = useInspectionStore();
  const prevAcceptedInsp = structure?.keyFields;
  let seismicVulnerability = getSeismicVulnerability(
    inspectionStore,
    structure?.UserBrdg?.SEISMIC_VULN
  );

  let nstmInspectionCondition = getCondition(
    inspectionStore,
    structure?.UserInsp?.NSTM_CONDITION
  );
  let docDefinition = {
    header: function () {
      return getReportHeader("Ratings Page", structure);
    },
    footer: function (currentPage, pageCount) {
      return getReportFooter(currentPage, pageCount);
    },
    pageMargins: [20, 70, 20, 50],
    content: [
      { text: "", pageHeaderText: "Ratings Page" },
      {
        table: {
          headerRows: 0,
          widths: ["100%"],
          body: [
            [
              {
                text: "Inspection Status",
                fillColor: "#ebebeb",
                alignment: "center",
                style: ["sectionHeader"],
              },
            ],
          ],
        },
        layout: "noBorders",
        margin: [0, 5, 0, 0],
      },
      {
        columns: [
          {
            width: "20%",
            text: "1A09 Inspection Status: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.INSPECTION_STATUS,
              structure?.InspEvnt?.INSPSTAT
            ),
            margin: [0, 5, 0, 0],
          },
        ],
      },
      {
        table: {
          headerRows: 0,
          widths: ["100%"],
          body: [
            [
              {
                text: "Key Field Comparison since Last Accepted Inspection",
                fillColor: "#ebebeb",
                alignment: "center",
                style: ["sectionHeader"],
              },
            ],
          ],
        },
        layout: "noBorders",
        margin: [0, 5, 0, 0],
      },
      {
        columns: [
          {
            width: "31%",
            text: "",
            margin: [0, 5, 0, 0],
          },
          {
            width: "7%",
            text: "Current",
            bold: true,
            alignment: "center",
            margin: [0, 3, 0, 0],
          },
          {
            width: "4%",
            text: "",
            margin: [0, 3, 0, 0],
          },
          {
            width: "9%",
            text: "Previous",
            bold: true,
            alignment: "center",
            margin: [0, 3, 0, 0],
          },
          {
            width: "16%",
            text: "",
            margin: [0, 3, 0, 0],
          },
          {
            width: "12%",
            text: "7A07 Required",
            bold: true,
            alignment: "center",
            margin: [0, 3, 0, 0],
          },
          {
            width: "8%",
            text: "",
            margin: [0, 3, 0, 0],
          },
          {
            width: "12%",
            text: "7A09 Interval",
            bold: true,
            alignment: "center",
            margin: [0, 3, 0, 0],
          },
        ],
      },
      {
        table: {
          headerRows: 0,
          widths: ["52%", "1%", "10%", "19%", "1%", "19%"],
          body: [
            [
              getRatingsTable(
                inspectionStore,
                structure,
                getFormattedDateStringNoTime,
                prevAcceptedInsp
              ),
              {
                text: "",
                margin: [0, 0, 0, 0],
              },
              {
                table: {
                  headerRows: 0,
                  widths: ["100%"],
                  body: [
                    [
                      {
                        text: "\n",
                      },
                    ],
                    [
                      {
                        text:
                          "NBI" +
                          getCheckboxChangedAsterisk(
                            "N/A",
                            "N/A",
                            structure?.InspEvnt?.BRINSPFREQ,
                            prevAcceptedInsp?.brInspectionFreq
                          ),
                        bold: true,
                        alignment: "center",
                        margin: [0, 1, 0, 0],
                      },
                    ],
                    [
                      {
                        text:
                          "NSTM" +
                          getCheckboxChangedAsterisk(
                            structure?.InspEvnt?.FCINSPREQ,
                            prevAcceptedInsp?.fcInspectionReq,
                            structure?.InspEvnt?.FCINSPFREQ,
                            prevAcceptedInsp?.fcInspectionFreq
                          ),
                        bold: true,
                        alignment: "center",
                        margin: [0, 1, 0, 0],
                      },
                    ],
                    [
                      {
                        text:
                          "UW" +
                          getCheckboxChangedAsterisk(
                            structure?.InspEvnt?.UWINSPREQ,
                            prevAcceptedInsp?.uwInspectionReq,
                            structure?.InspEvnt?.UWINSPFREQ,
                            prevAcceptedInsp?.uwInspectionFreq
                          ),
                        bold: true,
                        alignment: "center",
                        margin: [0, 0, 0, 0],
                      },
                    ],
                    [
                      {
                        text:
                          "OS" +
                          getCheckboxChangedAsterisk(
                            structure?.InspEvnt?.OSINSPREQ,
                            prevAcceptedInsp?.osInspectionReq,
                            structure?.InspEvnt?.OSINSPFREQ,
                            prevAcceptedInsp?.osInspectionFreq
                          ),
                        bold: true,
                        alignment: "center",
                        margin: [0, 1, 0, 0],
                      },
                    ],
                    [
                      {
                        text:
                          "Element" +
                          getCheckboxChangedAsterisk(
                            structure?.InspEvnt?.ELINSPREQ,
                            prevAcceptedInsp?.elInspectionReq,
                            structure?.InspEvnt?.ELINSPFREQ,
                            prevAcceptedInsp?.elInspectionFreq
                          ),
                        bold: true,
                        alignment: "center",
                        margin: [0, 0, 0, 0],
                      },
                    ],
                  ],
                },
                layout: "noBorders",
                margin: [0, 0, 0, 0],
              },
              {
                table: {
                  headerRows: 0,
                  widths: ["50%", "50%"],
                  body: [
                    [
                      {
                        text: "Current",
                        alignment: "center",
                      },
                      {
                        text: "Previous",
                        alignment: "center",
                      },
                    ],
                    [
                      {
                        text: "N/A",
                        alignment: "center",
                        margin: [0, 0, 0, 0],
                      },
                      {
                        text: "N/A",
                        alignment: "center",
                        margin: [0, 0, 0, 0],
                      },
                    ],
                    [
                      {
                        text: inspectionStore.getReportCheckbox(
                          structure?.InspEvnt?.FCINSPREQ
                        ),
                        alignment: "center",
                        style: "customFontAwesome",
                        margin: [0, 1.5, 0, 0],
                      },
                      {
                        text: inspectionStore.getReportCheckbox(
                          prevAcceptedInsp?.fcInspectionReq
                        ),
                        alignment: "center",
                        style: "customFontAwesome",
                        margin: [0, 1.5, 0, 0],
                      },
                    ],
                    [
                      {
                        text: inspectionStore.getReportCheckbox(
                          structure?.InspEvnt?.UWINSPREQ
                        ),
                        alignment: "center",
                        style: "customFontAwesome",
                        margin: [0, 1.5, 0, 0],
                      },
                      {
                        text: inspectionStore.getReportCheckbox(
                          prevAcceptedInsp?.uwInspectionReq
                        ),
                        alignment: "center",
                        style: "customFontAwesome",
                        margin: [0, 1.5, 0, 0],
                      },
                    ],
                    [
                      {
                        text: inspectionStore.getReportCheckbox(
                          structure?.InspEvnt?.OSINSPREQ
                        ),
                        alignment: "center",
                        style: "customFontAwesome",
                        margin: [0, 1.5, 0, 0],
                      },
                      {
                        text: inspectionStore.getReportCheckbox(
                          prevAcceptedInsp?.osInspectionReq
                        ),
                        alignment: "center",
                        style: "customFontAwesome",
                        margin: [0, 1.5, 0, 0],
                      },
                    ],
                    [
                      {
                        text: inspectionStore.getReportCheckbox(
                          structure?.InspEvnt?.ELINSPREQ
                        ),
                        alignment: "center",
                        style: "customFontAwesome",
                        margin: [0, 1.5, 0, 0],
                      },
                      {
                        text: inspectionStore.getReportCheckbox(
                          prevAcceptedInsp?.elInspectionReq
                        ),
                        alignment: "center",
                        style: "customFontAwesome",
                        margin: [0, 1.5, 0, 1],
                      },
                    ],
                  ],
                },
                layout: {
                  hLineWidth: function (i) {
                    return i < 2 || i > 5 ? 1 : 0;
                  },
                },
                margin: [0, 0, 0, 0],
              },

              {
                text: "",
                margin: [0, 0, 0, 0],
              },
              {
                table: {
                  headerRows: 0,
                  widths: ["50%", "50%"],
                  body: [
                    [
                      {
                        text: "Current",
                        alignment: "center",
                      },
                      {
                        text: "Previous",
                        alignment: "center",
                      },
                    ],
                    [
                      {
                        text: structure?.InspEvnt?.BRINSPFREQ + " mos",
                        alignment: "center",
                      },
                      {
                        text: prevAcceptedInsp?.brInspectionFreq
                          ? prevAcceptedInsp?.brInspectionFreq + " mos"
                          : "--",
                        alignment: "center",
                      },
                    ],
                    [
                      {
                        text: structure?.InspEvnt?.FCINSPFREQ + " mos",
                        alignment: "center",
                      },
                      {
                        text: prevAcceptedInsp?.fcInspectionFreq
                          ? prevAcceptedInsp?.fcInspectionFreq + " mos"
                          : "--",
                        alignment: "center",
                      },
                    ],
                    [
                      {
                        text: structure?.InspEvnt?.UWINSPFREQ + " mos",
                        alignment: "center",
                      },
                      {
                        text: prevAcceptedInsp?.uwInspectionFreq
                          ? prevAcceptedInsp?.uwInspectionFreq + " mos"
                          : "--",
                        alignment: "center",
                      },
                    ],
                    [
                      {
                        text: structure?.InspEvnt?.OSINSPFREQ + " mos",
                        alignment: "center",
                      },
                      {
                        text: prevAcceptedInsp?.osInspectionFreq
                          ? prevAcceptedInsp?.osInspectionFreq + " mos"
                          : "--",
                        alignment: "center",
                      },
                    ],
                    [
                      {
                        text: structure?.InspEvnt?.ELINSPFREQ + " mos",
                        alignment: "center",
                      },
                      {
                        text: prevAcceptedInsp?.elInspectionFreq
                          ? prevAcceptedInsp?.elInspectionFreq + " mos"
                          : "--",
                        alignment: "center",
                      },
                    ],
                  ],
                },
                layout: {
                  hLineWidth: function (i) {
                    return i < 2 || i > 5 ? 1 : 0;
                  },
                },
                margin: [0, 0, 0, 0],
              },
            ],
          ],
        },
        layout: "noBorders",
        margin: [0, 0, 0, 0],
      },
      {
        table: {
          headerRows: 0,
          widths: ["100%"],
          body: [
            [
              {
                text: "Condition Ratings",
                fillColor: "#ebebeb",
                alignment: "center",
                style: ["sectionHeader"],
              },
            ],
          ],
        },
        layout: "noBorders",
        margin: [0, 5, 0, 0],
      },
      {
        columns: [
          {
            width: "28%",
            text: "1A01 Deck: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "25%",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.CONDITION_RATING,
              structure?.InspEvnt?.DKRATING
            ),
            margin: [0, 5, 0, 0],
          },
          {
            width: "25%",
            text: "1A04 Superstructure: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.CONDITION_RATING,
              structure?.InspEvnt?.SUPRATING
            ),
            margin: [0, 5, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "28%",
            text: "1A02 Substructure: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "25%",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.CONDITION_RATING,
              structure?.InspEvnt?.SUBRATING
            ),
            margin: [0, 5, 0, 0],
          },
          {
            width: "25%",
            text: "1A05 Channel: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.CONDITION_RATING,
              structure?.InspEvnt?.CHANRATING
            ),
            margin: [0, 5, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "28%",
            text: "1A03 Culvert: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "25%",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.CONDITION_RATING,
              structure?.InspEvnt?.CULVRATING
            ),
            margin: [0, 5, 0, 0],
          },
          {
            width: "25%",
            text: "1A06 Waterway: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.CONDITION_RATING,
              structure?.InspEvnt?.WATERADEQ
            ),
            margin: [0, 5, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "28%",
            text: "1A15 NSTM Inspection Condition: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "25%",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.CONDITION_RATING,
              nstmInspectionCondition
            ),
            margin: [0, 5, 0, 0],
          },
          {
            width: "25%",
            text: "1A16 Lowest Condition Rating: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.CONDITION_RATING,
              structure?.UserInsp?.LOWEST_CONDITION
            ),
            margin: [0, 5, 0, 0],
          },
        ],
      },
      {
        text: "2A02 Inspection Notes: ",
        bold: true,
        margin: [0, 5, 0, 0],
      },
      {
        text: structure?.InspEvnt?.NOTES,
      },
      {
        table: {
          headerRows: 0,
          widths: ["100%"],
          body: [
            [
              {
                text: "Appraisal Ratings",
                fillColor: "#ebebeb",
                alignment: "center",
                style: ["sectionHeader"],
              },
            ],
          ],
        },
        layout: "noBorders",
        margin: [0, 5, 0, 0],
      },
      {
        columns: [
          {
            width: "28%",
            text: "IA02 Railing: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "25%",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.RAILING_RATING,
              structure?.InspEvnt?.RAILRATING
            ),
            margin: [0, 5, 0, 0],
          },
          {
            width: "25%",
            text: "4A02 Approach Alignment: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.APPROACH_ROADWAY_ALIGNMENT_APPRAISAL,
              structure?.InspEvnt?.APPRALIGN
            ),
            margin: [0, 5, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "28%",
            text: "IA02 Transition: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "25%",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.TRANSITION_RATING,
              structure?.InspEvnt?.TRANSRATIN
            ),
            margin: [0, 5, 0, 0],
          },
          {
            width: "25%",
            text: "6B38 Approach Slab: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.CONDITION_RATING,
              structure?.UserInsp?.APPR_SLAB_COND_RATE
            ),
            margin: [0, 5, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "28%",
            text: "IA02 Approach Guide Rail: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "25%",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.APPROACH_GUIDE_RAIL_RATING,
              structure?.InspEvnt?.ARAILRATIN
            ),
            margin: [0, 5, 0, 0],
          },
          {
            width: "25%",
            text: "6B39 Approach Roadway: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.CONDITION_RATING,
              structure?.UserInsp?.APPR_ROAD_COND_RATE
            ),
            margin: [0, 5, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "28%",
            text: "IA02 Approach Rail End: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "25%",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.APPROACH_RAIL_END_RATING,
              structure?.InspEvnt?.AENDRATING
            ),
            margin: [0, 5, 0, 0],
          },
          {
            width: "25%",
            text: "4A10 Deck Geometry: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.APPROACH_ROADWAY_ALIGNMENT_APPRAISAL,
              structure?.InspEvnt?.DECKGEOM
            ),
            margin: [0, 5, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "28%",
            text: "6B35 New Protective Coating? ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "25%",
            text: inspectionStore.getReportCheckbox(
              structure?.UserInsp?.NEW_PAINT
            ),
            style: "customFontAwesome",
            margin: [0, 5, 0, 0],
          },
          {
            width: "25%",
            text: "4A11 Underclearance: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.APPROACH_ROADWAY_ALIGNMENT_APPRAISAL,
              structure?.InspEvnt?.UNDERCLR
            ),
            margin: [0, 5, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "28%",
            text: "6B36 Protective Coating: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "25%",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.PAINT_CONDITION_RATING,
              structure?.UserInsp?.PAINT_COND_RATE
            ),
            margin: [0, 5, 0, 0],
          },
          {
            width: "25%",
            text: "6B40 Deck Wearing Surface: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.CONDITION_RATING,
              structure?.UserInsp?.DECK_WS_COND_RATE
            ),
            margin: [0, 5, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "28%",
            text: "6B37 Protective Coating (Extent): ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "25%",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.PAINT_CONDITION_RATING_EXTENT,
              structure?.UserInsp?.PAINT_EXTENT_RATE
            ),
            margin: [0, 5, 0, 0],
          },
          {
            width: "25%",
            text: "4A08 SCBI: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.SCOUR_CRITICAL_BRIDGE_INDICATOR,
              structure?.InspEvnt?.SCOURCRIT
            ),
            margin: [0, 5, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "28%",
            text: "4B03 Posting:",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "25%",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.POSTING,
              structure?.Bridge?.POSTING
            ),
            margin: [0, 5, 0, 0],
          },
          {
            width: "25%",
            text: "4A08b SCBI Category: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "*",
            text: structure?.InspEvnt?.SCOUR_CRIT_CTGRY,
            margin: [0, 5, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "28%",
            text: "4A26 Seismic Vulnerability:",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "25%",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.SEISMIC_VULNERABILITY,
              seismicVulnerability
            ),
            margin: [0, 5, 0, 0],
          },
          {
            width: "25%",
            text: "4A14 Bridge Condition: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.BRIDGE_CONDITION_RATING,
              structure?.InspEvnt?.CONDITION
            ),
            margin: [0, 5, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "53%",
            text: "",
            margin: [0, 5, 0, 0],
          },
          {
            width: "25%",
            text: "6B46 Deficiency Score: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "*",
            text: structure.UserInsp.MAINT_DEF_RATE,
            margin: [0, 5, 0, 0],
          },
        ],
      },
    ],
    styles: styles,
    defaultStyle: {
      fontSize: 10,
    },
  };
  return docDefinition;
}
function getNSTMInspectionCondition(structure) {
  if (
    structure?.UserBrdg?.MAIN_FC_GROUP_NUM < 5 ||
    structure?.UserBrdg?.APPR_FC_GROUP_NUM < 5
  ) {
    return structure?.InspEvnt?.SUPRATING;
  } else {
    return "N";
  }
}
function getCondition(inspectionStore, nstmInspectionCondition) {
  if (
    !nstmInspectionCondition &&
    !inspectionStore.getNstmInspectionConditionChangedByUser
  ) {
    return getNSTMInspectionCondition();
  } else {
    return nstmInspectionCondition;
  }
}
function getSeismicVulnerability(inspectionStore, seismicVulnerability) {
  if (
    !seismicVulnerability &&
    !inspectionStore.getSeismicVulnerabilityChangedByUser
  ) {
    return "0";
  } else {
    return seismicVulnerability;
  }
}
function checkPrevAcceptedInspection(value) {
  if (value) {
    return value;
  } else {
    return "--";
  }
}
function getCheckboxChangedAsterisk(
  currentRequired,
  previousRequired,
  currentInterval,
  previousInterval
) {
  let needsAsterisk = currentRequired != previousRequired;
  if (!needsAsterisk) {
    needsAsterisk =
      currentInterval == "0 mos" && previousInterval == "--"
        ? false
        : currentInterval != previousInterval;
  }

  return needsAsterisk ? "*" : "";
}
function getRatingsTable(
  inspectionStore,
  structure,
  getFormattedDateStringNoTime,
  prevAcceptedInsp
) {
  const fieldsToCompare = [
    {
      rowName: "7A01 Inspection Date:",
      currentCol: getFormattedDateStringNoTime(structure.InspEvnt.INSPDATE),
      previousCol: getFormattedDateStringNoTime(
        prevAcceptedInsp?.inspectionDate
      ),
      get differing() {
        return this.currentCol != this.previousCol ? " *" : "";
      },
    },
    {
      rowName: "7A03 Inspection Type:",
      currentCol: structure.InspEvnt.INSPTYPE || "--",
      previousCol: prevAcceptedInsp?.inspectionType || "--",
      get differing() {
        return this.currentCol != this.previousCol ? " *" : "";
      },
    },
    {
      rowName: "1A01 Deck:",
      currentCol: structure.InspEvnt.DKRATING || "--",
      previousCol: prevAcceptedInsp?.dkRating || "--",
      get differing() {
        return this.currentCol != this.previousCol ? " *" : "";
      },
    },
    {
      rowName: "1A04 Superstructure:",
      currentCol: structure.InspEvnt.SUPRATING || "--",
      previousCol: prevAcceptedInsp?.supRating || "--",
      get differing() {
        return this.currentCol != this.previousCol ? " *" : "";
      },
    },
    {
      rowName: "1A02 Substructure:",
      currentCol: structure.InspEvnt.SUBRATING || "--",
      previousCol: prevAcceptedInsp?.subRating || "--",
      get differing() {
        return this.currentCol != this.previousCol ? " *" : "";
      },
    },
    {
      rowName: "1A03 Culvert:",
      currentCol: structure.InspEvnt.CULVRATING || "--",
      previousCol: prevAcceptedInsp?.culvRating || "--",
      get differing() {
        return this.currentCol != this.previousCol ? " *" : "";
      },
    },
    {
      rowName: "IA02/B.C.05 Railings:",
      currentCol: structure.InspEvnt.RAILRATING || "--",
      previousCol: prevAcceptedInsp?.railRating || "--",
      get differing() {
        return this.currentCol != this.previousCol ? " *" : "";
      },
    },
    {
      rowName: "IA02/B.C.06 Transition:",
      currentCol: structure.InspEvnt.TRANSRATIN || "--",
      previousCol: prevAcceptedInsp?.transRating || "--",
      get differing() {
        return this.currentCol != this.previousCol ? " *" : "";
      },
    },
    {
      rowName: "IB01 Bearings:",
      currentCol: structure.T_Bearings?.[0]?.BEARING_OVERALL_COND_RATE || "--",
      previousCol: prevAcceptedInsp?.bearingsOverallCondRating || "--",
      get differing() {
        return this.currentCol != this.previousCol ? " *" : "";
      },
    },
    {
      rowName: "IJ01 Joints:",
      currentCol: structure.T_Joints?.[0]?.OVERALL_COND_RATING || "--",
      previousCol: prevAcceptedInsp?.jointsOverallCondRating || "--",
      get differing() {
        return this.currentCol != this.previousCol ? " *" : "";
      },
    },
    {
      rowName: "1A05 Channel:",
      currentCol: structure.InspEvnt.CHANRATING || "--",
      previousCol: prevAcceptedInsp?.chanRating || "--",
      get differing() {
        return this.currentCol != this.previousCol ? " *" : "";
      },
    },
    {
      rowName: "1A05b Channel Protection:",
      currentCol: structure.UserInsp.CHAN_PROT_RATING || "--",
      previousCol: prevAcceptedInsp?.chanProtRating || "--",
      get differing() {
        return this.currentCol != this.previousCol ? " *" : "";
      },
    },
    {
      rowName: "1A13 Scour:",
      currentCol: inspectionStore.getOverallScourHoles(structure),
      previousCol: checkPrevAcceptedInspection(
        inspectionStore.getPrevOverallScourHoles(prevAcceptedInsp)
      ),
      get differing() {
        return this.currentCol != this.previousCol ? " *" : "";
      },
    },
    {
      rowName: "1A14 Underwater:",
      currentCol: structure.UserInsp.UNDERWATER_RATING || "--",
      previousCol: prevAcceptedInsp?.underwaterRating || "--",
      get differing() {
        return this.currentCol != this.previousCol ? " *" : "";
      },
    },
    {
      rowName: "1A15 NSTM:",
      currentCol: structure.UserInsp.NSTM_CONDITION || "--",
      previousCol: prevAcceptedInsp?.nstmCondition || "--",
      get differing() {
        return this.currentCol != this.previousCol ? " *" : "";
      },
    },
    {
      rowName: "4A08 SCBI:",
      currentCol: structure.InspEvnt.SCOURCRIT || "--",
      previousCol: prevAcceptedInsp?.scourCritical || "--",
      get differing() {
        return this.currentCol != this.previousCol ? " *" : "";
      },
    },
    {
      rowName: "4A08b SCBI Category:",
      currentCol: structure.InspEvnt.SCOUR_CRIT_CTGRY || "--",
      previousCol: prevAcceptedInsp?.scourCriticalCat || "--",
      get differing() {
        return this.currentCol != this.previousCol ? " *" : "";
      },
    },
    {
      rowName: "IN15 Streambed Material:",
      currentCol: getIN15Value(
        structure.Structure_Unit,
        structure.T_Underwater_Insp
      ),
      previousCol: prevAcceptedInsp?.streamBedMaterial
        ? getIN15ValueFromMaterials(prevAcceptedInsp.streamBedMaterial)
        : "--",
      get differing() {
        return this.currentCol != this.previousCol ? " *" : "";
      },
      nonControllingChange: areThereNonControllingIN15Changes(
        structure.Structure_Unit,
        structure.T_Underwater_Insp,
        prevAcceptedInsp?.streamBedMaterial
      )
        ? "*"
        : "",
    },
    {
      rowName: "4A14 Bridge Condition:",
      currentCol: structure.InspEvnt.CONDITION || "--",
      previousCol: prevAcceptedInsp?.condition || "--",
      get differing() {
        return this.currentCol != this.previousCol ? " *" : "";
      },
    },
  ];

  let finalTable = [];
  fieldsToCompare.forEach((field) => {
    let row = [
      {
        text: field.rowName + field.differing,
        bold: true,
        margin: [0, 5, 0, 0],
      },
      {
        text: field?.nonControllingChange
          ? field.currentCol + field?.nonControllingChange
          : field.currentCol,
        alignment: "center",
        margin: [0, 5, 0, 0],
      },
      {
        text: field.previousCol,
        alignment: "center",
        margin: [0, 5, 0, 0],
      },
    ];
    finalTable.push(row);
  });

  return {
    table: {
      headerRows: 0,
      widths: ["56%", "22%", "22%"],
      body: finalTable,
    },
    layout: "noBorders",
    margin: [0, 0, 0, 0],
  };
}
export { setRatingsDocDefinition };
