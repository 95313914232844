<template>
  <v-row
    ><v-col>
      <v-label v-if="label" class="bold"
        >{{ label }}<span v-if="showColon">:</span>
        <span class="pl-1" v-if="labelValue">{{ labelValue }}</span></v-label
      >

      <v-text-field
        v-if="singleLine"
        :id="id"
        :aria-describedby="id"
        :aria-label="ariaLabel"
        v-model="modelValue"
        :readonly="readonly"
        :counter="counter"
        :maxlength="maxlength"
        persistent-counter
        single-line
        :variant="readonly ? 'outlined' : 'filled'"
      >
      </v-text-field>

      <v-textarea
        v-if="previousData"
        :id="id"
        :aria-describedby="id"
        :aria-label="ariaLabel"
        v-model="previousNotesVal"
        :readonly="readonly"
        :counter="counter"
        :maxlength="maxlength"
        persistent-counter
        auto-grow
        rows="1"
        :variant="readonly ? 'outlined' : 'filled'"
      >
      </v-textarea>

      <v-textarea
        v-else
        :id="id"
        v-model="modelValue"
        :aria-describedby="id"
        :aria-label="ariaLabel"
        :readonly="readonly"
        :counter="counter"
        :maxlength="maxlength"
        persistent-counter
        auto-grow
        rows="1"
        :variant="readonly ? 'outlined' : 'filled'"
        :required="isRequired"
        :rules="computedRules"
        @keypress="handler"
        @update:model-value="truncate"
        @blur="trim"
      >
        <template #details>
          <div class="d-flex justify-space-between">
            <v-spacer />
            <span
              class="inputErrorMessage"
              v-if="
                secondCharacterCount &&
                modelValueString?.length >= 1 &&
                modelValueString?.length < 50 &&
                !readonly
              "
              >{{ modelValueString?.length || 0 }} of 50 Characters Required
            </span>
            <span
              v-else-if="isRequired && !modelValueString && !readonly"
              class="inputErrorMessage"
            >
              Required
            </span>
          </div>
        </template>
      </v-textarea>
    </v-col></v-row
  >
</template>
<script setup>
import { computed, ref, toRefs, watch } from "vue";
import {
  secondCharacterCountRule,
  requiredRule,
} from "@/composables/validationRules";
import { truncateString, encodeString } from "@/composables/util";
import { clone } from "@/util/clone";

const props = defineProps({
  id: {},
  inspectionCommentType: { default: "" },
  readonly: { type: Boolean, default: false },
  counter: { default: "" },
  maxlength: { maxlength: "" },
  singleLine: { type: Boolean, default: false },
  label: { default: "" },
  labelValue: { default: "" },
  showColon: { type: Boolean, default: true },
  secondCharacterCount: { type: Boolean, default: false },
  previousData: { type: Boolean, default: false },
  isRequired: { type: Boolean, default: false },
  comments: { default: [] },
  previousComments: { default: [] },
  brkey: { default: null },
  inspectionId: { default: null },
});
const emits = defineEmits(["updateComment"]);
let { comments } = toRefs(props);
let localComments = ref(clone(comments.value));
const modelValueString = ref("");
const previousNotesVal = computed(() => {
  return props.previousComments?.find(
    (element) => element?.commentType == props.inspectionCommentType
  )?.notes;
});

const ariaLabel = computed({
  get() {
    if (props.label == "" || !props.label) {
      return props.id + " has no label";
    } else {
      return props.label + "_" + props.id;
    }
  },
});
function setElementByCommentType(object) {
  localComments.value.map((element) => {
    if (element?.commentType == object?.commentType) {
      element.notes = object.notes;
      element.updateUserId = object.updateUserId;
      element.updateDateTime = object.updateDateTime;
    }
  });
}

function getInspCommentObjectByCommentType(commentType) {
  return localComments.value?.find(
    (element) => element?.commentType == commentType
  );
}

function getNotesByCommentType(commentType) {
  const object = localComments.value?.find(
    (element) => element?.commentType == commentType
  );
  return object?.notes;
}

function elseCase(commentType, notes) {
  return {
    brkey: props.brkey,
    commentType: commentType,
    inspectionId: props.inspectionId,
    updateDateTime: null,
    notes: notes,
    updateUserId: null,
  };
}

const modelValue = computed({
  get() {
    return getNotesByCommentType(props.inspectionCommentType);
  },
  set(value) {
    modelValueString.value = encodeString(value);

    let commentObject = getInspCommentObjectByCommentType(
      props.inspectionCommentType
    );

    if (commentObject) {
      commentObject.notes = modelValueString.value;
      setElementByCommentType(commentObject.value);
    } else {
      commentObject = elseCase(
        props.inspectionCommentType,
        modelValueString.value
      );
      localComments.value.push(commentObject);
    }
    emits("updateComment", commentObject);
  },
});

const computedRules = computed(() => {
  let validationRules = [];

  if (props.rules && props.rules.length > 0) {
    validationRules = validationRules.concat(props.rules);
  }
  if (props.isRequired && !props.readonly) {
    validationRules.push(requiredRule);
  }
  if (props.secondCharacterCount && !props.readonly) {
    validationRules.push(secondCharacterCountRule(modelValueString.value));
  }
  return validationRules;
});

const handler = (event) => {
  if (event.which < 0x20 && event.which != 13) {
    // e.which < 0x20, then it's not a printable character like backspace etc.
    // e.which!=13 , enter key
    return; // Do nothing
  }

  if (modelValueString.value?.length >= parseInt(props.maxlength)) {
    event.preventDefault();
    return false;
  }
};

const truncate = () => {
  modelValue.value = truncateString(
    modelValueString.value?.replace(/\r\n|\r|\n/g, "\r\n"),
    parseInt(props.maxlength)
  );
};

const trim = () => {
  const trimmedString = modelValue.value?.trim();
  if (modelValue.value != trimmedString) {
    modelValue.value = modelValue.value?.trim();
  }
};

watch(
  () => [props],
  () => {
    localComments.value = clone(comments.value);
    modelValueString.value = modelValue.value;
  },
  { deep: true }
);
</script>
