import { defineStore } from "pinia";
import { ref } from "vue";
import { LOGGER } from "@/util/logger";
import { SNACKBAR_MESSAGE_TYPES } from "@/constants/GlobalSnackbar.js";

export const useSnackbarStore = defineStore("snackBar", () => {
  let messages = ref({});
  const _setMessageTimeout = (id) => {
    const message = messages.value[id];
    if (message.timeout && message.timeout > 0) {
      const timeoutId = setTimeout(() => {
        clearTimeout(timeoutId);
        removeMessage(id);
      }, message.timeout);
    }
    return () => delete messages[id];
  };
  const _generateId = () => {
    if (!_generateId.lastId) {
      _generateId.lastId = Date.now();
    }
    _generateId.lastId += 1;
    return `sb_${_generateId.lastId}`;
  };

  /**
   * Displays a message in the snackbar.
   *
   * @param {Object} options - The options for displaying the message.
   * @param {string} options.displayText - The text to be displayed in the snackbar.
   * @param {number} [options.timeout=-1] - The duration the message should be displayed before it is removed. Defaults to -1 (no timeout).
   * @param {string} [options.messageType=SNACKBAR_MESSAGE_TYPES.WARNING] - The type of the message (e.g., WARNING, INFO, SUCCESS). Defaults to WARNING.
   * @param {Function} [options.callback] - Optional callback function to be executed when the message is removed.
   * @param {boolean} [options.showCloseButton=false] - Whether to show a close button on the snackbar message. Defaults to false.
   * @param {string} [options.marker] - Optional marker to identify the message.
   * @returns {string} The ID of the newly displayed message.
   */
  const showMessage = ({
    displayText,
    timeout = -1,
    messageType = SNACKBAR_MESSAGE_TYPES.WARNING,
    callback,
    showCloseButton = false,
    marker,
  }) => {
    if (!displayText) {
      LOGGER.logException("display text is not provided.");
      return "";
    }
    if (!SNACKBAR_MESSAGE_TYPES[messageType.toUpperCase()]) {
      LOGGER.logException("display text is not provided.");
      return "";
    }
    const id = _generateId();
    messages.value[id] = {
      displayText,
      timeout,
      messageType,
      id,
      callback,
      showCloseButton,
      marker,
    };
    _setMessageTimeout(id);
    return id;
  };

  const removeMessage = (id) => {
    if (!id) {
      return;
    }
    const message = messages.value[id];
    if (message) {
      const callback = message.callback;
      delete messages.value[id];
      if (callback) {
        callback();
      }
    }
  };

  const removeMessagesByMarker = (marker) => {
    for (const id in messages.value) {
      const message = messages.value[id];
      if (message.marker === marker) {
        removeMessage(id);
      }
    }
  };

  const removeAllMessage = () => {
    for (const id in messages.value) {
      removeMessage(id);
    }
    messages.value = {};
  };

  return {
    messages,
    showMessage,
    removeMessage,
    removeAllMessage,
    removeMessagesByMarker,
  };
});
