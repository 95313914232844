export const SOURCE_TYPES = {
  PC: "pc",
  ECS: "ECS",
};

export const SECTION_TYPES = {
  INSPECTION_SUMMARY: 1,
  LOCATION_MAP: 2,
  BMS3_REPORTS: 3,
  PHOTOGRAPHS: 4,
  D491: 5,
  SCOUR_POA: 9,
  CUSTOM: 25,
};
