<template>
  <v-row>
    <v-col xl="4" lg="4" md="4" sm="12" xs="12">
      <v-table class="hide_scroll compactTable padding-xs">
        <thead>
          <tr>
            <th
              id="th_fieldComparison"
              aria-label="blankTableHeader"
              style="width: 55%"
              class="align-center"
            />
            <th id="th_current" class="align-center">Current</th>
            <th id="th_previous" class="align-center">Previous</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="field in fieldsToCompare" :key="field.rowName">
            <td
              class="boldFont"
              :class="{
                difference: field.differing || field.nonControllingChange,
              }"
            >
              {{ field.rowName }}: {{ field.differing ? "*" : "" }}
            </td>
            <td
              class="align-center"
              :class="{
                difference: field.differing || field.nonControllingChange,
              }"
            >
              <span :class="{ boldFont: field.nonControllingChange }"
                >{{ field.currentCol
                }}{{ field.nonControllingChange ? " *" : "" }}</span
              >
            </td>
            <td
              class="align-center"
              :class="{
                difference: field.differing || field.nonControllingChange,
              }"
            >
              {{ field.previousCol }}
            </td>
          </tr>
        </tbody>
      </v-table>
    </v-col>
    <v-col xl="8" lg="8" md="8" sm="12" xs="12">
      <v-row>
        <v-col align="center" xl="1" lg="2" md="2" sm="2" xs="2">
          <span>&nbsp;</span>
          <v-table class="noBorders hide_scroll compactTable pt-2 padding-xs">
            <thead>
              <tr>
                <th id="th_empty" aria-label="blankTableHeader">&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              <tr class="tableRowHeight">
                <td class="rightAlign pt-2 pb-1">NBI</td>
              </tr>
              <tr class="tableRowHeight">
                <td class="rightAlign pt-2 pb-1">NSTM</td>
              </tr>
              <tr class="tableRowHeight">
                <td class="rightAlign pt-2 pb-1">UW</td>
              </tr>
              <tr class="tableRowHeight">
                <td class="rightAlign pt-2 pb-1">OS</td>
              </tr>
              <tr class="tableRowHeight">
                <td class="rightAlign pt-2 pb-1">Element</td>
              </tr>
            </tbody>
          </v-table>
        </v-col>
        <v-col
          xl="3"
          lg="3"
          md="3"
          sm="3"
          xs="3"
          align="center"
          v-if="showPerformedData"
        >
          <v-label class="bold">7A06 Performed</v-label>
          <v-table class="hide_scroll compactTable padding-xs">
            <thead>
              <tr>
                <th id="th_current" class="align-center">Current</th>
                <th id="th_previous" class="align-center">Previous</th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="tableRowHeight align-center"
                v-for="performedInsp in performedInspToCompare"
                :key="performedInsp.rowName"
              >
                <td :class="{ difference: performedInsp.differing }">
                  <v-checkbox
                    class="d-flex justify-center"
                    id="{{ performedInsp.currentId }}"
                    v-model="performedInsp.currentModel"
                    true-value="1"
                    false-value="0"
                    disabled
                  />
                </td>
                <td :class="{ difference: performedInsp.differing }">
                  <v-checkbox
                    class="d-flex justify-center"
                    id="{{ performedInsp.previousId }}"
                    :model-value="performedInsp.previousModel"
                    true-value="1"
                    false-value="0"
                    disabled
                  />
                </td>
              </tr>
            </tbody>
          </v-table>
        </v-col>
        <v-col xl="3" lg="3" md="3" sm="3" xs="3" align="center">
          <v-label class="bold">7A07 Required</v-label>
          <v-table class="hide_scroll compactTable padding-xs">
            <thead>
              <tr>
                <th id="th_current" class="align-center">Current</th>
                <th id="th_previous" class="align-center">Previous</th>
              </tr>
            </thead>
            <tbody>
              <tr class="tableRowHeight align-center">
                <td>N/A</td>
                <td>N/A</td>
              </tr>
              <tr
                class="tableRowHeight align-center"
                v-for="requiredInsp in requiredInspToCompare"
                :key="requiredInsp.rowName"
              >
                <td :class="{ difference: requiredInsp.differing }">
                  <v-checkbox
                    class="d-flex justify-center"
                    id="{{ requiredInsp.currentId }}"
                    v-model="requiredInsp.currentModel"
                    true-value="1"
                    false-value="0"
                    disabled
                  />
                </td>
                <td :class="{ difference: requiredInsp.differing }">
                  <v-checkbox
                    class="d-flex justify-center"
                    id="{{ requiredInsp.previousId }}"
                    :model-value="requiredInsp.previousModel"
                    true-value="1"
                    false-value="0"
                    disabled
                  />
                </td>
              </tr>
            </tbody>
          </v-table>
        </v-col>
        <v-col xl="3" lg="3" md="3" sm="3" xs="3" align="center">
          <v-label class="bold">7A09 Interval</v-label>
          <v-table class="hide_scroll compactTable padding-xs">
            <thead>
              <tr>
                <th id="th_intervalCurrent" class="align-center">Current</th>
                <th id="th_intervalPrevious" class="align-center">Previous</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="interval in intervalsToCompare"
                :key="interval.rowName"
                class="tableRowHeight align-center"
              >
                <td :class="{difference: interval.differing }">
                  {{ interval.currentInterval}}
                </td>
                <td :class="{difference: interval.differing }">
                  {{ interval.previousInterval }}
                </td>
              </tr>
            </tbody>
          </v-table>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script setup>
import useDateField from "@/composables/dateField.js";
import {
  getIN15Value,
  getIN15ValueFromMaterials,
  areThereNonControllingIN15Changes,
} from "@/composables/keyFields.js";
import { useInspectionStore } from "@/stores/inspection";
import { computed } from "vue";

defineProps({
  showPerformedData: { default: false, type: Boolean },
});

const { getFormattedDateStringNoTime } = useDateField();
const inspectionStore = useInspectionStore();
const structure = computed(() => inspectionStore.selectedInspection);
const prevAcceptedInsp = computed(() => structure.value.keyFields);
const overallScourHoles = computed({
  get() {
    return inspectionStore.getOverallScourHoles(structure.value);
  },
});
const prevOverallScourHoles = computed({
  get() {
    return inspectionStore.getPrevOverallScourHoles(prevAcceptedInsp.value);
  },
});

const fieldsToCompare = [
  {
    rowName: "7A01 Inspection Date",
    currentCol: getFormattedDateStringNoTime(structure.value.InspEvnt.INSPDATE),
    previousCol: getFormattedDateStringNoTime(
      prevAcceptedInsp?.value?.inspectionDate
    ),
    get differing() {
      return this.currentCol != this.previousCol;
    },
  },
  {
    rowName: "7A03 Inspection Type",
    currentCol: structure.value.InspEvnt.INSPTYPE || "--",
    previousCol: prevAcceptedInsp?.value?.inspectionType || "--",
    get differing() {
      return this.currentCol != this.previousCol;
    },
  },
  {
    rowName: "1A01 Deck",
    currentCol: structure.value.InspEvnt.DKRATING || "--",
    previousCol: prevAcceptedInsp?.value?.dkRating || "--",
    get differing() {
      return this.currentCol != this.previousCol;
    },
  },
  {
    rowName: "1A04 Superstructure",
    currentCol: structure.value.InspEvnt.SUPRATING || "--",
    previousCol: prevAcceptedInsp?.value?.supRating || "--",
    get differing() {
      return this.currentCol != this.previousCol;
    },
  },
  {
    rowName: "1A02 Substructure",
    currentCol: structure.value.InspEvnt.SUBRATING || "--",
    previousCol: prevAcceptedInsp?.value?.subRating || "--",
    get differing() {
      return this.currentCol != this.previousCol;
    },
  },
  {
    rowName: "1A03 Culvert",
    currentCol: structure.value.InspEvnt.CULVRATING || "--",
    previousCol: prevAcceptedInsp?.value?.culvRating || "--",
    get differing() {
      return this.currentCol != this.previousCol;
    },
  },
  {
    rowName: "IA02/B.C.05 Railings",
    currentCol: structure.value.InspEvnt.RAILRATING || "--",
    previousCol: prevAcceptedInsp?.value?.railRating || "--",
    get differing() {
      return this.currentCol != this.previousCol;
    },
  },
  {
    rowName: "IA02/B.C.06 Transition",
    currentCol: structure.value.InspEvnt.TRANSRATIN || "--",
    previousCol: prevAcceptedInsp?.value?.transRating || "--",
    get differing() {
      return this.currentCol != this.previousCol;
    },
  },
  {
    rowName: "IB01 Bearings",
    currentCol:
      structure.value.T_Bearings?.[0]?.BEARING_OVERALL_COND_RATE || "--",
    previousCol: prevAcceptedInsp?.value?.bearingsOverallCondRating || "--",
    get differing() {
      return this.currentCol != this.previousCol;
    },
  },
  {
    rowName: "IJ01 Joints",
    currentCol: structure.value.T_Joints?.[0]?.OVERALL_COND_RATING || "--",
    previousCol: prevAcceptedInsp?.value?.jointsOverallCondRating || "--",
    get differing() {
      return this.currentCol != this.previousCol;
    },
  },
  {
    rowName: "1A05 Channel",
    currentCol: structure.value.InspEvnt.CHANRATING || "--",
    previousCol: prevAcceptedInsp?.value?.chanRating || "--",
    get differing() {
      return this.currentCol != this.previousCol;
    },
  },
  {
    rowName: "1A05b Channel Protection",
    currentCol: structure.value.UserInsp.CHAN_PROT_RATING || "--",
    previousCol: prevAcceptedInsp?.value?.chanProtRating || "--",
    get differing() {
      return this.currentCol != this.previousCol;
    },
  },
  {
    rowName: "1A13 Scour",
    currentCol:
      overallScourHoles.value && overallScourHoles.value != ""
        ? overallScourHoles.value
        : "--",
    previousCol:
      prevOverallScourHoles.value && prevOverallScourHoles.value != ""
        ? prevOverallScourHoles.value
        : "--",
    get differing() {
      return this.currentCol != this.previousCol;
    },
  },
  {
    rowName: "1A14 Underwater",
    currentCol: structure.value.UserInsp.UNDERWATER_RATING || "--",
    previousCol: prevAcceptedInsp?.value?.underwaterRating || "--",
    get differing() {
      return this.currentCol != this.previousCol;
    },
  },
  {
    rowName: "1A15 NSTM",
    currentCol: structure.value.UserInsp.NSTM_CONDITION || "--",
    previousCol: prevAcceptedInsp?.value?.nstmCondition || "--",
    get differing() {
      return this.currentCol != this.previousCol;
    },
  },
  {
    rowName: "4A08 SCBI",
    currentCol: structure.value.InspEvnt.SCOURCRIT || "--",
    previousCol: prevAcceptedInsp?.value?.scourCritical || "--",
    get differing() {
      return this.currentCol != this.previousCol;
    },
  },
  {
    rowName: "4A08b SCBI Category",
    currentCol: structure.value.InspEvnt.SCOUR_CRIT_CTGRY || "--",
    previousCol: prevAcceptedInsp?.value?.scourCriticalCat || "--",
    get differing() {
      return this.currentCol != this.previousCol;
    },
  },
  {
    rowName: "IN15 Streambed Material",
    currentCol: getIN15Value(
      structure.value.Structure_Unit,
      structure.value.T_Underwater_Insp
    ),
    previousCol: prevAcceptedInsp?.value?.streamBedMaterial
      ? getIN15ValueFromMaterials(prevAcceptedInsp.value.streamBedMaterial)
      : "--",
    get differing() {
      return this.currentCol != this.previousCol;
    },
    nonControllingChange: areThereNonControllingIN15Changes(
      structure.value.Structure_Unit,
      structure.value.T_Underwater_Insp,
      prevAcceptedInsp?.value?.streamBedMaterial
    ),
  },
  {
    rowName: "4A14 Bridge Condition",
    currentCol: structure.value.InspEvnt.CONDITION || "--",
    previousCol: prevAcceptedInsp?.value?.condition || "--",
    get differing() {
      return this.currentCol != this.previousCol;
    },
  },
];

const performedInspToCompare = [
  {
    currentId: "checkbox_nstm_Current",
    currentModel: structure.value.InspEvnt.NBINSPDONE,
    previousId: "checkbox_nstm_Previous",
    previousModel: prevAcceptedInsp?.value?.nbInspectionDone,
    get differing() {
      return this.currentModel != this.previousModel;
    },
  },
  {
    currentId: "checkbox_nstm_Current",
    currentModel: structure.value.InspEvnt.FCINSPDONE,
    previousId: "checkbox_nstm_Previous",
    previousModel: prevAcceptedInsp?.value?.fcInspectionDone,
    get differing() {
      return this.currentModel != this.previousModel;
    },
  },
  {
    currentId: "checkbox_uw_Current",
    currentModel: structure.value.InspEvnt.UWINSPDONE,
    previousId: "checkbox_uw_Previous",
    previousModel: prevAcceptedInsp?.value?.uwInspectionDone,
    get differing() {
      return this.currentModel != this.previousModel;
    },
  },
  {
    currentId: "checkbox_os_Current",
    currentModel: structure.value.InspEvnt.OSINSPDONE,
    previousId: "checkbox_os_Previous",
    previousModel: prevAcceptedInsp?.value?.osInspectionDone,
    get differing() {
      return this.currentModel != this.previousModel;
    },
  },
  {
    currentId: "checkbox_element_Current",
    currentModel: structure.value.InspEvnt.ELINSPDONE,
    previousId: "checkbox_element_Previous",
    previousModel: prevAcceptedInsp?.value?.elInspectionDone,
    get differing() {
      return this.currentModel != this.previousModel;
    },
  },
];

const requiredInspToCompare = [
  //NBI doesn't get an entry, since it's always required
  //NSTM
  {
    currentId: "checkbox_nstm_requiredCurrent",
    currentModel: structure.value.InspEvnt.FCINSPREQ,
    previousId: "checkbox_nstm_requiredPrevious",
    previousModel: prevAcceptedInsp?.value?.fcInspectionReq,
    get differing() {
      return this.currentModel != this.previousModel;
    },
  },
  {
    currentId: "checkbox_uw_requiredCurrent",
    currentModel: structure.value.InspEvnt.UWINSPREQ,
    previousId: "checkbox_uw_requiredPrevious",
    previousModel: prevAcceptedInsp?.value?.uwInspectionReq,
    get differing() {
      return this.currentModel != this.previousModel;
    },
  },
  {
    currentId: "checkbox_os_requiredCurrent",
    currentModel: structure.value.InspEvnt.OSINSPREQ,
    previousId: "checkbox_os_requiredPrevious",
    previousModel: prevAcceptedInsp?.value?.osInspectionReq,
    get differing() {
      return this.currentModel != this.previousModel;
    },
  },
  {
    currentId: "checkbox_element_requiredCurrent",
    currentModel: structure.value.InspEvnt.ELINSPREQ,
    previousId: "checkbox_element_requiredPrevious",
    previousModel: prevAcceptedInsp?.value?.elInspectionReq,
    get differing() {
      return this.currentModel != this.previousModel;
    },
  },
];

const intervalsToCompare = [
  {
    currentInterval: structure.value.InspEvnt.BRINSPFREQ + " mos",
    previousInterval: prevAcceptedInsp?.value?.brInspectionFreq
      ? prevAcceptedInsp?.value?.brInspectionFreq + " mos"
      : "--",
    get differing() {
      return this.currentInterval == "0 mos" && this.previousInterval == "--"
        ? false
        : this.currentInterval != this.previousInterval;
    },
  },
  {
    currentInterval: structure.value.InspEvnt.FCINSPFREQ + " mos",
    previousInterval: prevAcceptedInsp?.value?.fcInspectionFreq
      ? prevAcceptedInsp?.value?.fcInspectionFreq + " mos"
      : "--",
    get differing() {
      return this.currentInterval == "0 mos" && this.previousInterval == "--"
        ? false
        : this.currentInterval != this.previousInterval;
    },
  },
  {
    currentInterval: structure.value.InspEvnt.UWINSPFREQ + " mos",
    previousInterval: prevAcceptedInsp?.value?.uwInspectionFreq
      ? prevAcceptedInsp?.value?.uwInspectionFreq + " mos"
      : "--",
    get differing() {
      return this.currentInterval == "0 mos" && this.previousInterval == "--"
        ? false
        : this.currentInterval != this.previousInterval;
    },
  },
  {
    currentInterval: structure.value.InspEvnt.OSINSPFREQ + " mos",
    previousInterval: prevAcceptedInsp?.value?.osInspectionFreq
      ? prevAcceptedInsp?.value?.osInspectionFreq + " mos"
      : "--",
    get differing() {
      return this.currentInterval == "0 mos" && this.previousInterval == "--"
        ? false
        : this.currentInterval != this.previousInterval;
    },
  },
  {
    currentInterval: structure.value.InspEvnt.ELINSPFREQ + " mos",
    previousInterval: prevAcceptedInsp?.value?.elInspectionFreq
      ? prevAcceptedInsp?.value?.elInspectionFreq + " mos"
      : "--",
    get differing() {
      return this.currentInterval == "0 mos" && this.previousInterval == "--"
        ? false
        : this.currentInterval != this.previousInterval;
    },
  },
];
</script>
<style scoped lang="scss">
@use "@/styles/general" as g;
@use "@/styles/colors" as c;

.boldFont {
  font-weight: bold !important;
}

.tableRowHeight {
  height: 40px;
}

.rightAlign {
  text-align: right;
  font-weight: bold !important;
}

.difference {
  background-color: c.$e-yellow;
}
</style>
