import { defineStore } from "pinia";
import { ref, watch } from "vue";
import { INSPECTION_ENDPOINT, REPORTS_ENDPOINT } from "@/constants/Endpoints";
import axios from "axios";
import { LOGGER } from "@/util/logger";
import { useSnackbarStore } from "@/stores/snackbar";
import { SNACKBAR_MESSAGE_TYPES } from "@/constants/GlobalSnackbar.js";
import { downloadItem, base64ToArrayBuffer } from "@/util/downloadFile";
import { ENV_CONFIG_PROPERTY } from "@/constants/EnvConfigProperties";
import {
  SOURCE_TYPES,
  SECTION_TYPES,
} from "@/constants/InspectionReportGenerator.js";
import useDateField from "@/composables/dateField";
import { useConfigStore } from "@/stores/config";
import { REFERENCE_TABLE } from "@/constants/ReferenceTables";
import { useDocumentStore } from "@/stores/document";
import { clone } from "@/util/clone";
import { useAdminStore } from "@/stores/admin";
import { INSPECTION_STATUSES } from "@/constants/InspectionStatuses.js";
import { getPowerBIParams } from "@/composables/powerBIParam.js";
import { useUserStore } from "@/stores/userStore";

export const useInspectionReportGeneratorStore = defineStore(
  "inspectionReportGenerator",
  () => {
    const configStore = useConfigStore();
    let documentStore = useDocumentStore();
    let snackbarStore = useSnackbarStore();
    const adminStore = useAdminStore();
    const userStore = useUserStore();
    let isDisabled = ref(true);
    const { getFormattedDateStringNoTime } = useDateField();
    const irgData = ref([]);
    let brKey = ref(null);
    let selectedInspectionId = ref(null);
    let inspections = ref([]);
    let reportName = ref("");
    let isFormValid = ref(false);
    let inspectionReportGeneratorForm = ref(null);
    let docTypes = ref({});
    let showCircularProgress = ref(false);
    let customSection = ref({});
    let isGenerateReportEnabled = ref(false);
    let thinInspectionReportId = ref(null);
    let scourCriticalCategory = ref(null);

    const cleanStore = () => {};

    const validate = () => {
      isDisabled.value =
        !brKey.value || _getInspectionStatus() === INSPECTION_STATUSES.ACCEPTED;
      isGenerateReportEnabled.value = false;
      if (!_getSection(SECTION_TYPES.INSPECTION_SUMMARY).docContainerSeqNum) {
        isGenerateReportEnabled.value = false;
        return;
      }
      if (!_getSection(SECTION_TYPES.LOCATION_MAP).docContainerSeqNum) {
        isGenerateReportEnabled.value = false;
        return;
      }
      if (!_getSection(SECTION_TYPES.BMS3_REPORTS).docContainerSeqNum) {
        isGenerateReportEnabled.value = false;
        return;
      }
      if (!_getSection(SECTION_TYPES.PHOTOGRAPHS).docContainerSeqNum) {
        isGenerateReportEnabled.value = false;
        return;
      }
      let flag = false;
      irgData.value.forEach((item) => {
        if (item.includeInReport === "1" && !item.docContainerSeqNum && !flag) {
          flag = true;
        }
      });
      if (flag) {
        return;
      }
      isGenerateReportEnabled.value = true;
    };

    const referenceValue = (type) =>
      configStore.getReferenceValue(REFERENCE_TABLE.INSPECTION_TYPE, type);

    const resetData = (canCallEndpoint) => {
      const data = clone(configStore.getInspectionReportSection);
      irgData.value = [];
      data.forEach((item, id) => {
        const sectionTypeId = item.sectionTypeId;
        if (sectionTypeId === SECTION_TYPES.CUSTOM) {
          customSection.value = item;
        } else {
          let selectedDocTypeId = null;
          if (item.docTypeIds?.length === 1) {
            selectedDocTypeId = item.docTypeIds[0];
          }
          irgData.value.push(
            _getDefaultSectionObject({ id: id + 1, item, selectedDocTypeId })
          );
        }
      });
      reportName.value = "";
      if (canCallEndpoint) {
        fetchThinInspectionReport();
      }
    };

    const addCustomSection = (sectionName) => {
      let id = -1;
      irgData.value.forEach((section) => {
        if (section.id > id) {
          id = section.id;
        }
      });
      id += 1;
      irgData.value.push({
        ..._getDefaultSectionObject({
          id,
          item: customSection.value,
          selectedDocTypeId: null,
        }),
        sectionName,
        customSectionName: sectionName,
      });
      validate();
    };

    const uploadIRGDocument = async (document, sectionId) => {
      const file = document.file;
      const filename = document.filename;
      const description = document.docLabel;
      const docTypeId = document.docTypeId;

      try {
        const docUploadInfo = await documentStore.uploadDocument({
          brKey: brKey.value,
          docTypeId,
          filename,
          file,
          description,
        });
        irgData.value.find(
          (section) => section.id === sectionId
        ).docContainerSeqNum = docUploadInfo.docContainerSeqNum;
        await _getDocumentsByDocTypeId(docTypeId);
        validate();
        adminStore.setIsDirty(true);
      } catch (e) {
        snackbarStore.showMessage({
          displayText: "Operation Failed",
          messageType: SNACKBAR_MESSAGE_TYPES.ERROR,
          timeout: 10000,
        });
        LOGGER.logException(e);
      }
    };

    const fetchInspectionByBRKey = async (key) => {
      if (!key) {
        return;
      }
      showCircularProgress.value = true;
      let isSuccess = false;
      await axios
        .get(INSPECTION_ENDPOINT.GET_INSPECTIONS.replace(/\{brkey}/, key), {
          params: {
            inspectionStatuses: "2",
          },
        })
        .then((response) => {
          const data = response.data.data ?? [];
          if (data?.length > 0) {
            brKey.value = key;
            const sortedData = data.sort((a, b) => {
              const diff =
                new Date(b.inspectionDate).getTime() -
                new Date(a.inspectionDate).getTime();
              return diff;
            });
            selectedInspectionId.value = sortedData[0].inspectionId;
            inspections.value = [];
            sortedData.forEach((insp) => {
              const title =
                getFormattedDateStringNoTime(insp?.inspectionDate) +
                " - " +
                (insp.snbiInspectionTypes?.length
                  ? "Type " + insp.snbiInspectionTypes.join("")
                  : `${referenceValue(insp.inspectionType)}`);

              inspections.value.push({
                value: insp.inspectionId,
                title,
                inspectionStatus: insp.inspectionStatus,
                inspectionDate: insp.inspectionDate,
              });
            });
            validate();
            isSuccess = true;
          } else {
            showCircularProgress.value = false;
            brKey.value = null;
            selectedInspectionId.value = null;
            inspections.value = [];
            snackbarStore.showMessage({
              displayText:
                "There must be an inspection in “2 – Submitted” status in order to use the Inspection Report Generator.",
              messageType: SNACKBAR_MESSAGE_TYPES.ERROR,
              timeout: 10000,
            });
          }
        })
        .catch((e) => {
          LOGGER.logException(e);
          showCircularProgress.value = false;
          snackbarStore.showMessage({
            displayText: "Unable to fetch inspections.",
            messageType: SNACKBAR_MESSAGE_TYPES.ERROR,
            timeout: 10000,
          });
        });
      if (isSuccess) {
        await fetchThinInspectionReport();
      }
    };

    const fetchThinInspectionReport = async () => {
      if (!selectedInspectionId.value) {
        return;
      }
      showCircularProgress.value = true;
      const url = REPORTS_ENDPOINT.GET_THIN_INSPECTION_REPORTS.replace(
        /\{brkey}/,
        brKey.value
      ).replace(/\{inspectionId}/, selectedInspectionId.value);

      let isSuccess = false;
      await axios
        .get(url)
        .then((response) => {
          const thinInspectionReport = response.data.data?.[0] ?? {};
          thinInspectionReportId.value =
            thinInspectionReport.inspectionReportId || null;
          scourCriticalCategory.value =
            thinInspectionReport.scourCriticalCat || null;
          isSuccess = true;
        })
        .catch((e) => {
          snackbarStore.showMessage({
            displayText: "Unable to fetch previous inspection report.",
            messageType: SNACKBAR_MESSAGE_TYPES.ERROR,
            timeout: 10000,
          });
          LOGGER.logException(e);
          isSuccess = false;
        });
      showCircularProgress.value = false;
      if (isSuccess && thinInspectionReportId.value) {
        await fetchInspectionReport();
        irgData.value.forEach((section) => {
          if (section.docContainerSeqNum) {
            getAllowedDocTypes(section);
          }
        });
        validate();
      }
    };

    const fetchInspectionReport = async () => {
      if (!thinInspectionReportId.value) {
        return;
      }
      showCircularProgress.value = true;
      return axios
        .get(
          REPORTS_ENDPOINT.GET_INSPECTION_REPORTS_BY_BRKEY_AND_INSPECTION_REPORT_ID.replace(
            /\{brkey}/,
            brKey.value
          ).replace(/\{inspectionReportId}/, thinInspectionReportId.value)
        )
        .then((response) => {
          _mapInspectionReportData(response.data.data);
          showCircularProgress.value = false;
        })
        .catch((e) => {
          showCircularProgress.value = false;
          snackbarStore.showMessage({
            displayText:
              "Unable to fetch previously saved inspection report details.",
            messageType: SNACKBAR_MESSAGE_TYPES.ERROR,
            timeout: 10000,
          });
          LOGGER.logException(e);
        });
    };

    const generateReportHandler = async () => {
      showCircularProgress.value = true;
      const url =
        REPORTS_ENDPOINT.GET_GENERATE_INSPECTION_REPORT_PREVIEW.replace(
          /\{brkey}/,
          brKey.value
        ).replace(/\{inspectionReportId}/, thinInspectionReportId.value);

      await axios
        .get(url)
        .then((response) => {
          const { fileContent } = response.data.data;
          const fileName = `InspectionReport_${brKey.value}.pdf`;
          if (fileContent?.length > 0) {
            downloadItem({
              content: fileContent,
              fileType: "application/pdf",
              fileName,
            });
          } else {
            snackbarStore.showMessage({
              displayText: "Unavailable to generate preview document.",
              messageType: SNACKBAR_MESSAGE_TYPES.ERROR,
              timeout: 10000,
            });
          }
        })
        .catch(() => {
          snackbarStore.showMessage({
            displayText: "Unavailable to generate preview document.",
            messageType: SNACKBAR_MESSAGE_TYPES.ERROR,
            timeout: 10000,
          });
        });
      showCircularProgress.value = false;
    };

    const viewDocument = async (section) => {
      let edmsDoc = null;
      for (const docId of section.docTypeIds) {
        const list = docTypes.value?.[docId] || [];
        for (const doc of list) {
          if (doc.docContainerSeqNum === section.docContainerSeqNum) {
            edmsDoc = doc.edmsDocs[0];
            break;
          }
          if (edmsDoc !== null) {
            break;
          }
        }
      }
      if (edmsDoc) {
        showCircularProgress.value = true;
        const file = await documentStore.getDocument(brKey.value, edmsDoc);
        const { content, fileType, filename } = file ?? {};
        if (content?.length > 0) {
          downloadItem({ content, fileType, fileName: filename });
        } else {
          snackbarStore.showMessage({
            displayText: "File unavailable from ECS.",
            messageType: SNACKBAR_MESSAGE_TYPES.ERROR,
            timeout: 10000,
          });
        }
        showCircularProgress.value = false;
      } else {
        snackbarStore.showMessage({
          displayText: "File unavailable from ECS.",
          messageType: SNACKBAR_MESSAGE_TYPES.ERROR,
          timeout: 10000,
        });
      }
    };

    const changeCircularProgressVisibility = () => {
      showCircularProgress.value = !showCircularProgress.value;
    };

    const getAllowedDocTypes = async (section) => {
      showCircularProgress.value = true;
      for (const docId of section.docTypeIds) {
        await _getDocumentsByDocTypeId(docId, true);
      }
      showCircularProgress.value = false;
    };

    const saveAllChanges = async (showMessage) => {
      const url = REPORTS_ENDPOINT.PUT_INSPECTION_REPORTS_BY_BRKEY.replace(
        /\{brkey}/,
        brKey.value
      );
      const body = {
        brkey: brKey.value,
        inspectionId: selectedInspectionId.value,
        inspectionReportSections: [],
        reportName: reportName.value,
        docContainerSeqNums: [],
      };

      irgData.value.forEach((item, index) => {
        const section = {
          deleteFromECS: item.deleteFromECS === "1",
          docContainerSeqNum: item.docContainerSeqNum,
          includeInReport: item.includeInReport === "1",
          orderId: index + 1,
          sectionTypeId: item.sectionTypeId,
          toc: item.toc === "1",
          customSectionName: item.customSectionName,
        };

        if (item.inspectionReportSectionId) {
          section.inspectionReportSectionId = item.inspectionReportSectionId;
        }

        if (thinInspectionReportId.value) {
          section.inspectionReportId = thinInspectionReportId.value;
        }

        body.inspectionReportSections.push(section);
      });

      if (thinInspectionReportId.value) {
        body.inspectionReportId = thinInspectionReportId.value;
      }

      let isSuccess = false;
      await axios
        .put(url, body)
        .then((response) => {
          _mapInspectionReportData(response.data.data);
          isSuccess = true;
          if (showMessage) {
            snackbarStore.showMessage({
              displayText: "Changes are saved.",
              messageType: SNACKBAR_MESSAGE_TYPES.SUCCESS,
              timeout: 10000,
            });
          }
        })
        .catch(() => {
          isSuccess = false;
        });
      return isSuccess;
    };

    const generateSectionReportHandler = async (
      sectionId,
      sectionTypeId,
      data
    ) => {
      showCircularProgress.value = true;
      switch (sectionTypeId) {
        case SECTION_TYPES.INSPECTION_SUMMARY:
          await _generateInspectionSummary(sectionId, data);
          break;
        case SECTION_TYPES.LOCATION_MAP:
          await _generateLocationMap(sectionId);
          break;
        case SECTION_TYPES.BMS3_REPORTS:
          await _generateBMS3Report(sectionId, data);
          break;
        case SECTION_TYPES.D491:
          await _generatePowerBIReport(
            sectionId,
            ENV_CONFIG_PROPERTY.D491_REPORT,
            getPowerBIParams({
              sectionTypeId: SECTION_TYPES.D491,
              brKey: brKey.value,
              user: _getUser(),
            }),
            "D491"
          );
          break;
        case SECTION_TYPES.SCOUR_POA:
          await _generatePowerBIReport(
            sectionId,
            ENV_CONFIG_PROPERTY.SCOUR_POA,
            getPowerBIParams({
              sectionTypeId: SECTION_TYPES.SCOUR_POA,
              brKey: brKey.value,
              user: _getUser(),
              inspectionDate: new Date(
                inspections.value.find(
                  (item) => item.value === selectedInspectionId.value
                )?.inspectionDate
              )
                .toISOString()
                .split("T")[0],
            }),
            "ScourPOA"
          );
          break;
        default:
          console.log("no matching report section");
      }
      showCircularProgress.value = false;
      validate();
      adminStore.setIsDirty(true);
    };

    const _generateInspectionSummary = async (sectionId, document) => {
      showCircularProgress.value = true;
      const url = INSPECTION_ENDPOINT.POST_INSPECTION_SUMMARY_REPORT.replace(
        /\{brkey}/,
        brKey.value
      ).replace(/\{inspectionId}/, selectedInspectionId.value);

      const file = document.file;
      const filename = document.filename;
      const description = document.docLabel;
      const docType = document.docTypeId;

      const form = new FormData();
      const photoMetaData = { filename, docType, description };
      form.append("json", JSON.stringify(photoMetaData));
      form.append("file", file);
      await axios
        .post(url, form)
        .then((response) => {
          const section = irgData.value.find(
            (section) => section.id === sectionId
          );
          section.docContainerSeqNum = response.data.data.docContainerSeqNum;
          getAllowedDocTypes(section);
        })
        .catch((e) => {
          snackbarStore.showMessage({
            displayText: "Operation Failed",
            messageType: SNACKBAR_MESSAGE_TYPES.ERROR,
            timeout: 10000,
          });
          LOGGER.logException(e);
        });
      showCircularProgress.value = false;
    };

    const _generateLocationMap = async (sectionId) => {
      showCircularProgress.value = true;
      await axios
        .post(
          REPORTS_ENDPOINT.POST_LOCATION_MAP_GENERATOR.replace(
            /\{brkey}/,
            brKey.value
          )
        )
        .then((response) => {
          const section = irgData.value.find(
            (section) => section.id === sectionId
          );
          section.docContainerSeqNum = response.data.data.docContainerSeqNum;
          getAllowedDocTypes(section);
          showCircularProgress.value = false;
        })
        .catch((e) => {
          showCircularProgress.value = false;
          snackbarStore.showMessage({
            displayText: "Operation Failed",
            messageType: SNACKBAR_MESSAGE_TYPES.ERROR,
            timeout: 10000,
          });
          LOGGER.logException(e);
        });
    };

    const _generateBMS3Report = async (sectionId, docUploadInfo) => {
      const section = irgData.value.find((section) => section.id === sectionId);
      section.docContainerSeqNum = docUploadInfo.data.data.docContainerSeqNum;
      getAllowedDocTypes(section);
    };

    const _generatePowerBIReport = async (
      sectionId,
      envConfigValue,
      parameterValues,
      postFixFileName
    ) => {
      let reportCode = configStore.getEnvConfigValue(envConfigValue);
      const filename = `${brKey.value}_${postFixFileName}.pdf`;
      if (reportCode) {
        const exportRequestObj = {
          format: "PDF",
          paginatedReportConfiguration: {
            parameterValues,
          },
        };

        let url = REPORTS_ENDPOINT.EXPORT_REPORT.replace(
          "{reportId}",
          reportCode
        );
        const response = await axios.post(url, exportRequestObj);
        const { fileContent, fileType = "application/pdf" } =
          response?.data?.data ?? {};
        if (fileContent?.length > 0) {
          const file = new File([base64ToArrayBuffer(fileContent)], filename, {
            type: fileType,
          });
          const section = irgData.value.find(
            (section) => section.id === sectionId
          );
          await uploadIRGDocument(
            {
              file,
              filename,
              docLabel: filename,
              docTypeId: section.docTypeIds[0],
            },
            sectionId
          );
        }
      }
    };

    const _getDocumentsByDocTypeId = async (
      docTypeId,
      isProgressControlled
    ) => {
      if (!isProgressControlled) {
        showCircularProgress.value = true;
      }
      docTypes.value[docTypeId] = await documentStore.getDocumentsByDocType(
        brKey.value,
        docTypeId
      );
      if (!isProgressControlled) {
        showCircularProgress.value = false;
      }
    };

    const _getSection = (sectionId) => {
      return (
        irgData.value.find((section) => section.sectionTypeId === sectionId) ??
        {}
      );
    };

    const _getDefaultSectionObject = ({ id, item, selectedDocTypeId }) => {
      return {
        id,
        deleteFromECS: item.deleteFromECSDefault,
        docTypeIds: item.docTypeIds,
        sectionName: item.sectionName,
        includeInReport: item.includeInReportDefault,
        sectionTypeId: item.sectionTypeId,
        toc: item.tocDefault,
        selectedDocTypeId,
        sourceIsPCOrECS: SOURCE_TYPES.PC,
        docContainerSeqNum: null,
        inspectionReportSectionId: null,
      };
    };

    const _getValueFromConfig = (sectionTypeId, defaultData, propName) => {
      return defaultData.find((item) => {
        return sectionTypeId === item.sectionTypeId;
      })[propName];
    };

    const _mapInspectionReportData = (data) => {
      const defaultData = clone(configStore.getInspectionReportSection);
      thinInspectionReportId.value = data.inspectionReportId;
      reportName.value = data.reportName;
      selectedInspectionId.value = data.inspectionId;
      const inspectionReportSections = [];
      const sortedInspectionReportSections = data.inspectionReportSections.sort(
        (a, b) => a.orderId - b.orderId
      );
      sortedInspectionReportSections.forEach((item, index) => {
        const deleteFromECS = item.deleteFromECS ? "1" : "0";
        const includeInReport = item.includeInReport ? "1" : "0";
        const toc = item.toc ? "1" : "0";
        let selectedDocTypeId = null;
        const section = defaultData.find((s) => {
          return s.sectionTypeId === item.sectionTypeId;
        });
        if (section.docTypeIds?.length === 1) {
          selectedDocTypeId = section.docTypeIds[0];
        }
        let sectionName = _getValueFromConfig(
          item.sectionTypeId,
          defaultData,
          "sectionName"
        );
        if (item.sectionTypeId === SECTION_TYPES.CUSTOM) {
          sectionName = item.customSectionName ?? section.sectionName;
        }
        inspectionReportSections.push({
          id: item.inspectionReportSectionId || index + 1,
          sectionName,
          customSectionName: item.customSectionName,
          docTypeIds: _getValueFromConfig(
            item.sectionTypeId,
            defaultData,
            "docTypeIds"
          ),
          deleteFromECS:
            _getValueFromConfig(
              item.sectionTypeId,
              defaultData,
              "deleteFromECSDefault"
            ) === "-1"
              ? "-1"
              : deleteFromECS,
          docContainerSeqNum: item.docContainerSeqNum,
          includeInReport:
            _getValueFromConfig(
              item.sectionTypeId,
              defaultData,
              "includeInReportDefault"
            ) === "-1"
              ? "-1"
              : includeInReport,
          inspectionReportSectionId: item.inspectionReportSectionId,
          orderId: item.orderId,
          sectionTypeId: item.sectionTypeId,
          toc:
            _getValueFromConfig(
              item.sectionTypeId,
              defaultData,
              "tocDefault"
            ) === "-1"
              ? "-1"
              : toc,
          selectedDocTypeId,
          sourceIsPCOrECS: SOURCE_TYPES.PC,
        });
      });
      irgData.value = inspectionReportSections;
    };

    const _getUser = () =>
      configStore.getUserId(userStore.getUserSub())?.toString() || "";

    const _getInspectionStatus = () =>
      inspections.value.find(
        (item) => item.value === selectedInspectionId.value
      )?.inspectionStatus;

    const _onSelectedInspectionIdChange = () => {
      validate();
    };

    validate();

    watch(
      () => [selectedInspectionId.value],
      () => {
        _onSelectedInspectionIdChange();
      },
      { deep: true }
    );
    return {
      brKey,
      scourCriticalCategory,
      docTypes,
      isDisabled,
      isGenerateReportEnabled,
      isFormValid,
      irgData,
      inspectionReportGeneratorForm,
      reportName,
      selectedInspectionId,
      showCircularProgress,
      inspections,

      addCustomSection,
      cleanStore,
      changeCircularProgressVisibility,
      fetchInspectionByBRKey,
      fetchThinInspectionReport,
      generateReportHandler,
      generateSectionReportHandler,
      getAllowedDocTypes,
      resetData,
      saveAllChanges,
      uploadIRGDocument,
      viewDocument,
      validate,
    };
  }
);
