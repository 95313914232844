/**
 * sortStructure is used to sort the table in a report
 * based on structure property and field name.
 * example propery and fieldName is below
 *      -----------------------------------------------------
 *      |           |   structureProperty   |   fieldName   |
 *      -----------------------------------------------------
 *      |   ID01    |   T_Signing           |   SIGN_TYPE   |
 *      |   5D01    |   Structure_Unit      |   STRUNITKEY  |
 *      |   5C03    |   Roadway             |   ON_UNDER    |
 *      -----------------------------------------------------
 * @param {Array} structure - The array of objects to sort.
 * @param {string} structureProperty - The property name within each object.
 * @param {string} fieldName - The field to compare.
 * @param {boolean} [isAscending=true] - Whether to sort in ascending order (default) or descending order.
 * @return {Array} - The sorted array.
 */
export const sortStructure = (
  structure,
  structureProperty,
  fieldName,
  isAscending = true
) => {
  return (
    structure?.[structureProperty]?.sort((a, b) => {
      const valueA = a[fieldName];
      const valueB = b[fieldName];

      // Handle numeric and string values differently
      if (typeof valueA === "number" && typeof valueB === "number") {
        return compareFn(valueA, valueB, isAscending);
      } else {
        // Use localeCompare for string values
        return String(valueA).localeCompare(String(valueB));
      }
    }) || []
  );
};

/**
 * Compares two values and returns:
 * -1 if `a` is less than `b` (or greater if `isAscending` is false)
 *  1 if `a` is greater than `b` (or less if `isAscending` is false)
 *  0 if `a` is equal to `b`
 *
 * @param {*} a - The first value to compare.
 * @param {*} b - The second value to compare.
 * @param {boolean} isAscending - Whether to sort in ascending order (true) or descending order (false).
 * @return {number} - The comparison result (-1, 0, or 1).
 */
function compareFn(a, b, isAscending) {
  if (a < b) {
    return isAscending ? -1 : 1;
  } else if (a > b) {
    return isAscending ? 1 : -1;
  }
  return 0;
}

/**
 * Sorts an array of objects by two specified properties with optional sort orders.
 *
 * @param {Object[]} arr - The array of objects to be sorted.
 * @param {string} property1 - The primary property to sort by.
 * @param {string} property2 - The secondary property to sort by if the primary properties are equal.
 * @param {string} [order1='asc'] - The sort order for the primary property ('asc' for ascending, 'desc' for descending).
 * @param {string} [order2='asc'] - The sort order for the secondary property ('asc' for ascending, 'desc' for descending).
 */
export const sortByTwoProperties = (arr, property1, property2, order1 = 'asc', order2 = 'asc') => {
  arr.sort((a, b) => {
    let comparePrimary;
    let compareSecondary;

    if (typeof a[property1] === 'number' && typeof b[property1] === 'number') {
      comparePrimary = a[property1] - b[property1];
    } else {
      comparePrimary = (a[property1] + "").localeCompare(b[property1] + "");
    }

    if (typeof a[property2] === 'number' && typeof b[property2] === 'number') {
      compareSecondary = a[property2] - b[property2];
    } else {
      compareSecondary = (a[property2] + "").localeCompare(b[property2] + "");
    }

    if (a[property1] === b[property1]) {
      return order2 === 'asc' ? compareSecondary : -compareSecondary;
    }
    return order1 === 'asc' ? comparePrimary : -comparePrimary;
  });
};
